import React, { useState } from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import InternBanner from "../components/internship/banner"
import { Link } from "gatsby"
import Offering from "../components/internship/offering"
import privacyPolicy from "../assets/images/privacy-policy.jpg"
import InterForm from "../components/internship/internForm"
import InternShipAccordian from "../components/internship/accordian"

const Intership = () => {
  const [internshipTitle, setInternshipTitle] = useState("")
  const applyNowFunction = title => {
    setInternshipTitle(title)
    // window.location.href = "#jobform"
  }
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Intership"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Intership"
      />
      <InternBanner />
      <InternShipAccordian applyFunc={applyNowFunction} />
      {internshipTitle !== "" ? (
        <section className="privacy-policy-area ptb-100">
          <InterForm selectedInternship={internshipTitle} />
        </section>
      ) : null}
      <Footer />
    </Layout>
  )
}

export default Intership
