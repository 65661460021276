import React from "react"
import { Link } from "gatsby"
import projectStart from "../../assets/images/project-start1.png"
import shape from "../../assets/images/shape/circle-shape1.png"

const InternBanner = props => {
  return (
    <div className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img src={projectStart} alt="project" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>Internships</h2>
              <p>
                If you're a student with an interest in Technical fields such as
                Web Development, Mobile App Development, Designing, and Digital
                Marketing, then apply for one of our internship programs.
              </p>

              {/* <Link to="/#jobform" className="default-btn"> */}
              <a href="/#jobform" className="default-btn">
                Apply Now
              </a>
              <span></span>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape} alt="project" />
      </div>
    </div>
  )
}
export default InternBanner
